<template>
    <button
      class="flexible-button"
      :class="{ disabled: props.disabled }"
      :type="btnType"
      :disabled="disabled"
    >
      <slot/>
    </button>
</template>

<script setup lang="ts">
import { ButtonHTMLAttributes, defineProps, PropType } from 'vue';

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    btnType: {
      type: String as PropType<ButtonHTMLAttributes['type']>,
      default: 'button'
    }
});
</script>

<style src="./buttonBackgroundComponent.scss" lang="scss" />
