<template>
  <header class="header">
    <div class="header__container">
      <div class="header__logo" @click="dropAllFilters">
        <router-link :to="{ name: 'start' }">
          <img src="@/assets/images/icons/Logo.svg" alt="Logo" />
        </router-link>
      </div>
      <nav class="header__nav">
        <router-link class="nav__title" :to="{name: 'start'}" @click="dropAllFilters">
          {{ $t('webshop') }}
        </router-link>
        <div class="dropdown">
          <span class="nav__title dropdown-toggle">
            {{ $t('angebot') }}
          </span>
          <div class="dropdown-content">
            <router-link class="nav__link" :to="{name: 'kataloge'}">
              {{ $t('kataloge') }}
            </router-link>
            <router-link class="nav__link" :to="{name: 'maschinenservice'}">
              {{ $t('maschinenservice') }}
            </router-link>
            <router-link class="nav__link" :to="{name: '3d_druck'}">
              {{ $t('3D druck') }}
            </router-link>
            <router-link class="nav__link" :to="{name: 'workshop_and_schuhlungen'}">
              {{ $t('schuhlungen') }}
            </router-link>
          </div>
        </div>
        <div class="dropdown">
          <span class="nav__title dropdown-toggle">
            {{ $t('uber uns') }}
          </span>
          <div class="dropdown-content">
            <router-link class="nav__link" :to="{name: 'team'}">
              {{ $t('team') }}
            </router-link>
            <router-link class="nav__link" :to="{name: 'kontakt'}">
              {{ $t('kontakt') }}
            </router-link>
          </div>
        </div>
      </nav>
      <div class="header__tools">
        <div class="dropdown">
          <span class="nav__title">
            {{ selectCountry }}
          </span>
          <div class="dropdown-content">
            <span class="nav__link" @click="changeLocal(key, value as string)" v-for="(key, value) in translateStore.language" :key="key">
              {{ value }}
            </span>
          </div>
        </div>
        <router-link v-if="tokenStore.access" :to="{ name: 'favorites' }">
          <div class="wrapper-cart">
            <img src="@/assets/images/icons/heart.png" alt="Favorites" />
            <div class="cart-count favorites" :class="{skeleton: favoritesStore.loading}">
              <span>{{ favoritesStore.count }}</span>
            </div>
          </div> 
      </router-link>
        <router-link v-if="tokenStore.access" :to="{name: 'cart'}">
          <div class="wrapper-cart">
            <img src="@/assets/images/icons/cart.png" alt="Cart Icon" />
            <div class="cart-count" :class="{skeleton: shoppingCartStore.loading}">
              <span>{{ cardCount }}</span>
            </div>
          </div>
        </router-link>
        <HeaderAuthTooltipComponent />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useTokenStore } from "@/store/modules/TokenOperationStoreGroup/TokenOperation";
import HeaderAuthTooltipComponent from "@/components/HeaderComponentGroup/HeaderAuthTooltipComponent/HeaderAuthTooltipComponent.vue";
import { useUserTranslate } from "@/store/modules/TranslateStoreGroup/TranslateStore";
import { useFavoriteStore } from "@/store/modules/FavoriteStoreGroup/FavoriteStore";
import { useShoppingCartStore } from '@/store/modules/ShoppingCartStoreGroup/ShoppingCartStore';
import { Language } from "@/store/modules/Translate/type";
import { useI18n } from 'vue-i18n';
import { computed } from "vue";
import { useCardStore } from "@/store/modules/WebshopStoreGroup/CardStoreGroup/CardStoreGroup";
import { useCategoriesStore } from "@/store/modules/WebshopStoreGroup/CategoriesGroup/CategoriesGroup";
import { userDataStore } from "@/store/modules/AccountAngabenStoreGroup/UserGroup/userData";

const tokenStore = useTokenStore();
const translateStore = useUserTranslate();
const selectCountry = computed(() => translateStore.languageSelect.substring(0, 2));
const favoritesStore = useFavoriteStore();
const shoppingCartStore = useShoppingCartStore();
const { locale } = useI18n();

const cardStore = useCardStore();
const categoriesStore = useCategoriesStore();
const userStore = userDataStore();
const cardCount = computed(() => shoppingCartStore.count);
const dropAllFilters = () => {
  categoriesStore.select = null;
  categoriesStore.parent = null;
  cardStore.search = '';
  cardStore.getDataSearch();
}

const changeLocal = (_locale: Language, value: string) => {
  if(userStore.user.id) {
    translateStore.updateLanguageUser(value);
  }
  translateStore.languageSelect = _locale;
  locale.value = _locale;
}
</script>

<style lang="scss" src="./HeaderComponent.scss" />