<template>
    <div class="wrapper-ui-link">
        <div class="back" @click="router.back"/>
        <span
            v-for="(breadcrumbs, idx) in breadcrumbsData"
            @click="router.push({ name: breadcrumbs.to})"
            :key="breadcrumbs.name"
            class="breadcrumbs"
            :class="{ last: idx === breadcrumbsData.length -1, delimeter:  idx < breadcrumbsData.length && idx !== 0 }"
        >
            <span class="breadcrumbs__name">{{ $t(breadcrumbs.name) }}</span>
        </span>
    </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';
import { BreadCrumbsRouter } from './type';
import { computed } from 'vue';

const route = useRoute();
const router = useRouter();
const breadcrumbsData = computed(() => route.meta.breadcrumbs as BreadCrumbsRouter[]);
</script>

<style lang="scss" src="./breadCrumbsComponent.scss" scoped/>
