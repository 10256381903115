import { Translate } from "./type";

export const Deutsch:Translate = {
    'email is a required field': "E-Mail ist ein Pflichtfeld",
    'password is a required field': "Passwort ist ein Pflichtfeld",
    'copy address': 'Adresse kopieren',
    'Workshop and Schuhlungen': 'Workshop und Schulungen',
    kontakt: 'Kontakt',
    partner: 'Partner',
    geschichte: 'Geschichte',
    team: 'Team',
    'uber uns': 'Über uns',
    'workshop & schuhlungen': 'Workshops & Schulungen',
    '3D druck': '3D-Druck',
    maschinenservice: 'Maschinenservice',
    kataloge: 'Kataloge',
    angebot: 'Angebot',
    geschlossen: 'Geschlossen',
    uhr: 'Uhr',
    'samstag und sonntag': 'Samstag und Sonntag',
    freitag: 'Freitag',
    öffnungszeiten: 'Öffnungszeiten',
    'montag - donnerstag': 'Montag - Donnerstag',
    impressum: 'Impressum',
    'schuh - und orthopädiebedarf': 'Schuh- und Orthopädiebedarf',
    'rudolf roth ag': 'Rudolf Roth AG',
    'anmelden oder login beantragen': 'Anmelden oder Login beantragen',
    logout: 'Logout',
    webshop: 'Webshop',
    schuhlungen: 'Schulungen',
    'unser team': 'Unser Team',
    'mein konto': 'Mein Konto',
    'erledigte bestellungen': 'Erledigte Bestellungen',
    'pendente bestellungen': 'Pendente Bestellungen',
    rückgaben: 'Rückgaben',
    'defekt melden': 'Defekt melden',
    accountangaben: 'Accountangaben',
    'änderungen speichern': 'Änderungen speichern',
    rechnungsadresse: 'Rechnungsadresse',
    'plz': 'PLZ',
    'ein land': 'Ein Land',
    'die stadt': 'Die Stadt',
    adresse: 'Adresse',
    kundennummer: 'Kundennummer',
    email: 'Email',
    'kennwort ändern': 'Kennwort ändern',
    'neue adresse hinzufügen': 'Neue Adresse hinzufügen',
    kontodetails: 'Kontodetails',
    firma: 'Firma',
    kontaktperson: 'Kontaktperson',
    telefonnummer: 'Telefonnummer',
    'zusatzliche telefonnummer': 'Zusätzliche Telefonnummer',
    'gln-nummer': 'GLN-Nummer',
    'mwst-nummer': 'MwSt-Nummer',
    lieferadresse: 'Lieferadresse',
    'bestellung wiederholen': 'bestellung wiederholen',
    bezahlstatus: "bezahlstatus",
    gesamtbetrag: "gesamtbetrag",
    size: "größe",
    colour: "farbe",
    'melden sie sich in ihrem konto an': 'melden sie sich in ihrem konto an',
    email_login: 'email',
    'email oder Kundennummer': 'e-mail oder kundennummer',
    password: 'passwort',
    'nicht registriert?': 'nicht registriert?',
    'ein Konto erstellen': 'ein Konto erstellen',
    'passwort vergessen?': 'passwort vergessen?',
    'leer': 'leer',
    'wird geladen': 'wird geladen',
    "willkürlich": "willkürlich",
    "festgelegt": "festgelegt",
    "min length 1": "Mindestlänge 1",
    'bilder hochladen': 'bilder hochladen',
    'bestellnummer': 'bestellnummer',
    'defektbeschreibung': 'defektbeschreibung',
    delete: 'delete', 
    'in den warenkorb': 'in den warenkorb',
    'wonach suchst du?': 'wonach suchst du?',
    category: 'kategorie',
    'leerer warenkorb': 'leerer warenkorb',
    'zurück zum einkaufen': 'zurück zum einkaufen',
    bestellen: 'bestellen',
    'zum warenkorb hinzufügen': 'zum warenkorb hinzufügen',
    'menge auf lager':'menge auf lager',
    favoritinnen: 'favoritinnen',
    kasse: 'kasse',
    versandart: 'versandart',
    lieferung: 'lieferung',
    'Lieferadresse wählen': 'Lieferadresse wählen',
    'Andere Adresse': 'Andere Adresse', 
    rezension: 'Rezension',
    zahlung: 'Zahlung',
    "We’re on it!": "We’re on it!",
    'Your payment has been received and you\'ll get notifications for your order\'s state.': 'Ihre Zahlung ist eingegangen und Sie erhalten Benachrichtigungen zum Status Ihrer Bestellung.',
    'Zurück zum Einkaufen': 'Zurück zum Einkaufen',
    'Something went wrong': 'Etwas ist schiefgelaufen',
    'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.': 'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.',
    "versand": "Versand",
    "post": "Postleitzahl",
    "warenkorb": "Warenkorb",
    "Total": "Gesamt",
    "List": "Liste",
    "Price": "Preis",
    "Quantity": "Menge",
    "Total Price": "Gesamtpreis",
    "color": "Farbe",
    "Rechnung erhalten per": "Rechnung erhalten per",
    "Versandart": "Versandart",
    "List of products": "Liste der Produkte",
    "Photo": "Foto",
    "Warenkorb": "Warenkorb",
    'Unsere Partner': 'Unsere Partner',
    'Schulungen': 'Schulungen',
    Kataloge: 'Kataloge',
    Hauptkatalog: 'Hauptkatalog',
    Gummi: 'Gummi',
    Orthopädie: 'Orthopädie',
    "Leder": "Leder",
    "Chemie": "Chemie",
    "Beschläge": "Beschläge",
    "Schuhpflege": "Schuhpflege",
    "Werkzeuge": "Werkzeuge",
    "Maschinen": "Maschinen",
    anrede: "Anrede",
    vorname: "Vorname",
    "Treten sie mit uns in kontakt": "Treten Sie mit uns in Kontakt",
    nachname: "Nachname",
    strasse: "Straße",
    'nr.': "Nr.",
    'nr': "Nr.",
    postcode: "Postleitzahl",
    ort: "Ort",
    phone: "Telefon",
    "Ihre Nachricht": "Ihre Nachricht",
    "Rudolf Roth AG": "Rudolf Roth AG",
    "Schuh- und Orthopädiebedarf": "Schuh- und Orthopädiebedarf",
    "Öffnungszeiten": "Öffnungszeiten",
    "Montag - Donnerstag": "Montag - Donnerstag",
    Freitag: "Freitag",
    "7.30 Uhr- 12.00 Uhr": "7.30 Uhr - 12.00 Uhr",
    "13.00 Uhr - 17.00 Uhr": "13.00 Uhr - 17.00 Uhr",
    "Samstag und Sonntag": "Samstag und Sonntag",
    Geschlossen: "Geschlossen",
    Senden: "Senden",
    "Maschinenservicek": "Maschinenservice",
    "Ein Maschinenservice-Anforderungsformular": "Formular für Maschinenservice-Anforderung",
    "name": "Name",
    "maschinenbezeichnung": "Maschinenbezeichnung",
    "Maschinennummer": "Maschinennummer",
    "Problem Beschreibung": "Problem Beschreibung",
    "Maschinen service": "Maschinenservice",
    "Workshop and Schulungen": 'Workshop und Schulungen',
    kataloge_1: 'Unser Hauptkatalog besteht aus 9 Teilen. Durch Klicken auf das entsprechende Bild oder den Text gelangen Sie jeweils zu einer PDF-Datei. Diese Kataloge werden laufend aktualisiert.',
    'Anfrageformular für einen Workshop': 'Anfrageformular für einen Workshop',
    'Anfrageformular für einen 3D Druck': 'Anfrageformular für einen 3D Druck',
    "Geschichte_1": "Die Rudolf Roth AG in Gwatt-Thun (Orthopädiebedarf, Schuhpflege, Lederpflege) ist seit 1949 ein kompetenter Ansprechpartner für Schuhmacher, orthopädische Werkstätten, Schuhfach- und Sportgeschäfte, ein Familienunternehmen.",
    "Geschichte_2": "Als schweizweit bekannter Spezialist vertreibt die Firma alle in der Schuhbranche verwendeten Artikel aus den Bereichen Gummi, Orthopädie, Leder, Chemische Artikel, Beschläge, Reparaturteile, Garne, Furnituren, Werkzeuge, Maschinen und Maschinenteile.",
    "Geschichte_3": "Die Pflege der Geschäftsbeziehungen durch den persönlichen Kontakt zum Kunden gehört ebenso zur seriösen Geschäftsphilosophie wie die hohe Qualität der Produkte und die Belieferung der Kunden innerhalb kurzer Frist.",
    'Ihr Warenkorb': 'Ihr Warenkorb',
    'Nettobetrag': 'Nettobetrag',
    'MWST':'MWST',
    'Stärke':'Stärke',
    'Shore':'Shore',
    'Maßeinheiten':'Maßeinheiten'
}
