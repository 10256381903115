<template>
  <div class="wrapper-input-group"> 
    <div class="sub-wrapper-input-group" :class="{ skeleton: loading }">
      <input
        class="input-group"
        v-model="value"
        :name="name"
        :placeholder="placeholder"
        :maxlength="max"
        :class="{ input_error: errorMessage }"
        :disabled="disabled"
      />
      <span
        v-if="!disabled"
        class="input-group__delete-icon"
        :class="{'input-group__delete-icon_error': errorMessage}"
        @click="handleChange('', true)"
        v-show="value"
      />
    </div>
    <span class="input-group__error" v-if="errorMessage">{{ $t(`${errorMessage}`) }}</span>
  </div> 
</template>
<script setup lang="ts">
import { defineProps } from "vue";
import { useField } from "vee-validate";

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  max: {
    type: Number,
    default: 55,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  }
});

const { value, errorMessage, handleChange  } = useField(() => props.name);
</script>

<style lang="scss" src="./inputComponent.scss" />
