<template>
  <div class="user-login">
    <span v-if="!tokenStore.access">
      <img src="@/assets/images/icons/icon_user.png" alt="user_img" />
    </span>
    <router-link v-else :to="{name: 'mein_konto'}">
      <img src="@/assets/images/icons/icon_user_auth.png" alt="user_img" />
    </router-link>
    <div class="tooltip">
      <span class="tooltip-login" v-if="!tokenStore.access" @click="popupStore.changeOpenPopupStore(true)">
        {{ $t('anmelden oder login beantragen') }}
      </span>
      <div class="tooltip-user" v-else>
        <div class="tooltip__user-info">
          <img class="tooltip__user-img" src="" alt="">
          <div class="sub-tooltip__user-info">
            <span>{{ 'user-name' }}</span>
            <span>{{ 'user-code' }}</span>
          </div>
        </div>
        <span class="tooltip-logout" @click="logoutHandler">
          {{ $t('logout') }}
        </span> 
      </div>
    </div>
  </div>
  <PopupComponent :name="popup.name">
    <PopupLoginGroup/>
  </PopupComponent>
</template>

<script setup lang="ts">
import { useTokenStore } from '@/store/modules/TokenOperationStoreGroup/TokenOperation';
import PopupComponent from '@components/PopupGroup/PopupComponent.vue';
import PopupLoginGroup from '@components/PopupGroup/PopupLoginGroup/PopupLoginComponent.vue';
import { usePopupStore } from '@/store/modules/PopupStoreGroup/PopupStore';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

const popup = reactive({ name: 'login' })
const popupStore = usePopupStore(popup.name);
const tokenStore = useTokenStore();
const router = useRouter();

const logoutHandler = () => tokenStore.userLogout().finally(() => {
  tokenStore.access = null;
  router.push({ name: 'start' });
});
</script>

<style lang="scss" src="./HeaderAuthTooltipComponent.scss" />
