import { IUniversalDataReturns } from "@/store/type";
import axios from "axios";
import { Categories, ICategoriesState } from "./type";
import { defineStore } from "pinia";

export const useCategoriesStore = defineStore("categoriesStore", {
  state: (): ICategoriesState => ({
    loading: true,
    error: false,
    count: 0,
    next: null,
    previous: null,
    results: [],
    select: null,
    parent: null,
  }),
  actions: {
    async getCategories(search?: string) {
        try {
            this.results = [];
            this.loading = true;
            const { data } = await axios.get<IUniversalDataReturns<Categories>>(process.env.VUE_APP_SERVER + 'api/categories/', {
              params: {
                search,
               },
            });
            this.results = data.results;
            this.count = data.count;
            this.previous = data.previous;
            this.next = data.next;
        } catch (e) {
            this.error = true;
            console.log(e);
        } finally {
            this.loading = false;
        }
    },
  },
  getters: {
    getCategoryById: (state) => (id: number) => {
      const findObjectById = (objects: Categories[], id: number): Categories | undefined  => {
        const findItemAndParentsRecursive = (item: Categories): Categories | undefined => {
          if (item.id === id) {
              return item;
          }
          if( item.children)
          for (const child of item.children) {
              const result = findItemAndParentsRecursive(child);
              if (result) {
                  return item;
              }
          }
          return undefined;
      }
  
      for (const item of objects) {
          const result = findItemAndParentsRecursive(item);
          if (result) {
              return result;
          }
      }
  
        return undefined;
      }
      return findObjectById(state.results, id);
    },
  }
})