<template>
  <div class="wrapper-input-group">
    <div class="sub-wrapper-input-group" :class="{ skeleton: loading }">
      <input
        v-maska
        :data-maska="mask"
        class="input-group"
        v-model="value"
        :name="name"
        :placeholder="placeholder"
        :maxlength="20"
        :class="{ input_error: errorMessage }"
      />
      <VueTelInput
        v-maska
        v-show="false"
        v-model="valuePhone"
        :data-maska="mask"
        :defaultCountry="'SE'"
        :autoDefaultCountry="false"
        inputClasses="'input-group'"
        :mode="'international'"
      />
      <span
        class="input-group__delete-icon"
        :class="{'input-group__delete-icon_error': errorMessage}"
        @click="handleChange('', true)"
        v-show="value"
      />
    </div>
    <span class="input-group__error">{{ errorMessage }}</span>
  </div>
</template>

<script setup lang="ts">
import { VueTelInput } from 'vue-tel-input';
import { ref, defineProps } from "vue";
import { useField } from "vee-validate";
import { vMaska } from "maska";
import 'vue-tel-input/vue-tel-input.css';
import { watch } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const mask = ref<string>("+41 ## ### ## ##");
const valuePhone = ref<string>('');

const { value, errorMessage, handleChange } = useField<string>(props.name);

watch(() => value.value, (newState) => {
  valuePhone.value = newState;
});

watch(() => valuePhone.value, (newState) => {
  mask.value = newState.replace(/[0-9]/g, "#");
});
</script>

<style lang="scss" src="./inputComponentPhone.scss" />
