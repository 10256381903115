import { defineStore } from 'pinia';
import { IuseKontodetailsStore, useKontodetailsStoreGroup } from './type';

export const useKontodetailsStore = defineStore('useKontodetailsStore', {
  state: (): useKontodetailsStoreGroup => ({
    initialValue: {
      firma: '',
      kontaktperson: '',
      telefonenumber: '',
      zusatzlicheTelefonnummer:'',
      mwst_num: '',
      gln_num: '',
    }
  }),
  getters: {
    getInitialState(state) {
      return {
        ...state.initialValue,
        telefonenumber: state.initialValue.telefonenumber,
        zusatzlicheTelefonnummer: state.initialValue.zusatzlicheTelefonnummer,
      };
    },
  },
  actions: {
    setInitialState(state: IuseKontodetailsStore) {
      this.initialValue = {...state};
    }
  }
});