import { defineStore } from 'pinia';
import axios from 'axios';
import { UserDataInterface, UserType } from './type';
import { useTokenStore } from '@/store/modules/TokenOperationStoreGroup/TokenOperation';
import { Language } from '@/store/modules/TranslateStoreGroup/type';

export const userDataStore = defineStore('userDataStore', {
  state: (): UserDataInterface => ({
    loading: true,
    user: {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        title: null,
        job_title:null,
        photo_path: null,
        phone: null,
        customer: null,
        location: null,
        external_user_id: null,
        orders_filter: {
            date__gte: null,
            date__lte: null,
        },
        ui_language: Language.DE,
        locale: null,
    }
  }),
  actions: {
    async getUserData(id: number) {
        try {
            this.loading = true;
            const tokenStore = useTokenStore();
            const { data } = await axios.get<UserType>(process.env.VUE_APP_SERVER + `api/users/${id}/`, {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
            this.user = data;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    },
    async updateUserData(values: Partial<UserType>){
        try {
            this.loading = true;
            const tokenStore = useTokenStore();
            const { data } = await axios.put<UserType>(process.env.VUE_APP_SERVER + `api/users/${this.user.id}/`, { ...values }, {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
              this.user = data;
          } catch (e) {
              console.error(e);
          } finally {
              this.loading = false;
          }
    }
  },
});
