<template>
    <div class="drag-and-drop" :class="{error: errorMessage}">
        <div v-show="state.files.length" class="files">
            <div class="file-item" v-for="(file, index) in state.files" :key="index">
                <span>{{ file.name }}</span>
                <span 
                  class="drag-and-drop__delete"
                  @click="handleClickDeleteFile(index)"
                >
                    {{ $t('delete') }}
                </span>
            </div>
        </div>
        <div class="file-item" v-bind="getRootProps()">
          <img src="@/assets/images/icons/add_photo.svg" alt="add_photo" />
          <span>{{ props.placeholder }}</span>
        </div>
        <input v-bind="getInputProps()" :name="props.name" />
    </div>
    <span class="input-group__error">{{ errorMessage }}</span>
</template>

<script setup lang="ts">
import { FileRejectReason, useDropzone } from "vue3-dropzone";
import { defineProps, reactive } from 'vue';
import { FileUpload } from './type';
import { useField } from "vee-validate";
import { watch } from "vue";
import { computed } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  maxSize: {
    type: Number,
    default: 10485760,
  },
  maxFiles: {
    type: Number,
    default: 1,
  },
  acceptFiles: {
    type: Function,
    default: (acceptFiles: File[]) => {
        console.log(acceptFiles);
    },
  },
  rejectReasons: {
    type: Function,
    default: (rejectReasons: FileRejectReason[]) => {
        console.log(rejectReasons);
    },
  },
  accept: {
    type: String,
    default: "image/*",
  },
  deleteAllFiles: {
    type: Boolean,
    default: false,
  },
});

const state = reactive<FileUpload>({
  files: [],
});

const handleClickDeleteFile = (index: number) => {
  state.files.splice(index, 1);
  props.acceptFiles(state.files);
}

const onDrop = (acceptFiles: File[], rejectReasons: FileRejectReason[]) => {
  state.files = [...state.files, ...acceptFiles];
  props.acceptFiles(state.files);
  props.rejectReasons(rejectReasons);

  resetField({
    errors: undefined,
  });
}

const deleteAllFiles = computed(() => props.deleteAllFiles);

watch(deleteAllFiles, () => {
  state.files = [];
}, {
  deep: true,
});

const { getRootProps, getInputProps } = useDropzone({ onDrop, maxSize: props.maxSize, maxFiles: props.maxFiles, accept: props.accept });
const { resetField, errorMessage  } = useField(() => props.name);
</script>

<style src="./DragAndDropFileUploaderComponent.scss" lang="scss"/>
  