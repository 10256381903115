import { createI18n } from 'vue-i18n'
import { Deutsch } from './translate/Deutsch';
import { Italian } from './translate/Italian';
import { French } from './translate/French';

const Language = {
    DE: Deutsch,
    IT: Italian,
    FR: French,
};

export const LanguageKeys = Object.keys(Language);

export const i18n = createI18n({
    locale: 'DE',
    legacy: false,
    messages: Language,
});
