import axios from 'axios';
import { createPinia } from 'pinia';
import { useTokenStore } from './modules/TokenOperationStoreGroup/TokenOperation';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

export const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

axios.interceptors.request.use(async (config) => {
    if(config.headers.Authorization) {
        const tokenStore = useTokenStore();
        const check = await tokenStore.checkToken();

        if(!check) {
            await tokenStore.refreshAccessToken();
            const check = await tokenStore.checkToken();
            
            if(check) {
                config.headers.Authorization = `Bearer ${tokenStore.access}`;
            }
        }

    }
     
    return config;
});