<template>
    <label
        class="kasse-location"
        @click="emit('click')"
    >
        <input :type="props.typeInput" :value="checkedValue" :name="props.name" v-model="value"/>
        {{ props.title }}
    </label>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
    name: {
        default: 'field',
    },
    title: {
        default: 'field',
    },
    checkedValue: {
        default: null,
    },
    typeInput: {
        default: 'radio',
    },
});

const emit = defineEmits(['click']);

const { value } = useField(() => props.name);
</script>
