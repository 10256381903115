import { defineStore } from 'pinia';
import type { CreateLocationsType, LocationsStoreGroupState, LocationsType } from '../type';
import { useTokenStore } from '../../TokenOperationStoreGroup/TokenOperation';
import axios from 'axios';

export const useLocationsStore = (name: string) => defineStore(`useLocationsStore_${name}`, {
  state: (): LocationsStoreGroupState<LocationsType> => ({
    locations: {
        id: null,
        geo_place: null,
        geo_place_name: null,
        geo_place_region: null,
        country: null,
        city: null,
        postal_code: null,
        full_address: null,
        house: null,
        street: null,
        geo_latitude: null,
        geo_longitude: null,
        status: null,
    },
    loading: true,
  }),
  actions: {
    async getLocations(id: number) {
        try {
            this.loading = true;
            const tokenStore = useTokenStore();
            const { data } = await axios.get<LocationsType>(process.env.VUE_APP_SERVER + `api/locations/${id}/`, {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
            this.locations = data;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    },
    async setLocations(state: CreateLocationsType) {
        try {
            this.loading = true;
            const tokenStore = useTokenStore();
            const { data } = await axios.post<LocationsType>(process.env.VUE_APP_SERVER + 'api/locations/',
            { ...state },
            {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
            this.locations = data;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    },
    async updateLocations(state: CreateLocationsType, id: number) {
        try {
            this.loading = true;
            const tokenStore = useTokenStore();
            const { data } = await axios.put<LocationsType>(process.env.VUE_APP_SERVER + `api/locations/${id}/`,
            { ...state },
            {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
            this.locations = data;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    }
  },
})();
