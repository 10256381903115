import { defineStore } from 'pinia';
import { CreateCustomerType, CustomerDataInterface, CustomerType } from './type';
import { useTokenStore } from '@/store/modules/TokenOperationStoreGroup/TokenOperation';
import axios from 'axios';

export const customerDataStore = defineStore('customerDataStore', {
  state: (): CustomerDataInterface<CustomerType> => ({
    loading: true,
    customers: {
        id: null,
        firm_id: null,
        firm_name: null,
        firm_name2: null,
        manager: null,
        firm_status: null,
        customer_type: null,
        phone: null,
        fax: null,
        currency: null,
        buyer_name: null,
        buyer_phone: null,
        buyer_address: null,
        location: null,
        buyer_vat: null,
        creation_time: null,
        email: null,
        payment_type: null,
        sales_person: null,
        email_notify: null,
    },
  }),
  actions: {
    async getCustomersData(id: number) {
      try {
        this.loading = true;
        const tokenStore = useTokenStore();
        const { data } = await axios.get<CustomerType>(process.env.VUE_APP_SERVER + `api/customers/${id}/`, {
            headers: { Authorization: `Bearer ${tokenStore.access}` },
            withCredentials: true,
        });
          this.customers = data;
      } catch (e) {
          console.error(e);
      } finally {
          this.loading = false;
      }
    },
    async setCustomersData(values: CreateCustomerType) {
      try {
        this.loading = true;
        const tokenStore = useTokenStore();
        const { data } = await axios.post<CustomerType>(process.env.VUE_APP_SERVER + `api/customers/`, { ...values }, {
            headers: { Authorization: `Bearer ${tokenStore.access}` },
            withCredentials: true,
        });
          this.customers = data;
      } catch (e) {
          console.error(e);
      } finally {
          this.loading = false;
      }
    },
    async updateCustomersData(values: CreateCustomerType) {
      try {
        this.loading = true;
        const tokenStore = useTokenStore();
        const { data } = await axios.put<CustomerType>(process.env.VUE_APP_SERVER + `api/customers/${this.customers.id}/`, { ...values }, {
            headers: { Authorization: `Bearer ${tokenStore.access}` },
            withCredentials: true,
        });
          this.customers = data;
      } catch (e) {
          console.error(e);
      } finally {
          this.loading = false;
      }
    },
  },
});
