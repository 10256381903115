import { defineStore } from 'pinia';
import { AccessToken, DecodeTokenType, TokenStoreGroupState } from './type';
import jwtDecode, { JwtPayload }  from "jwt-decode";
import axios from 'axios';
import { ILoginGroupSchema } from '@/yup/angabenGroupSchema/type';

export const useTokenStore = defineStore('useTokenStore', {
  state: (): TokenStoreGroupState => ({
    access: null,
    loading: true,
    decode: null,
  }),
  getters: {

  },
  actions: {
    async userLogin(loginData: ILoginGroupSchema) {
      const { data } = await axios.post<AccessToken>(process.env.VUE_APP_SERVER + 'api/token/', { ...loginData, remember_me: true, secret: loginData.password }, {
        withCredentials: true,
      });
      this.access = data.access;
      this.decodeUser();
    },
    async refreshAccessToken() {
      try {
        const { data } = await axios.post<AccessToken>(process.env.VUE_APP_SERVER + 'api/token/refresh/', undefined, {
          withCredentials: true,
        });
        this.access = data.access;
        this.decodeUser();
        return true;
      } catch (e) {
        return false;
      } finally {
        this.loading = false;
      }
    },
    decodeUser(): void {
      if(this.access) {
        this.decode = jwtDecode<DecodeTokenType>(this.access);
      }
    },
    async userLogout() {
      try {
        await axios.get(process.env.VUE_APP_SERVER + 'api/logout/', {
          headers: { Authorization: `Bearer ${this.access}` },
          withCredentials: true,
      });
      } catch (e) {
        console.log(e);
      }
    },
    checkToken(): boolean {
      try {
        if(this.access) {
            const decoded = jwtDecode<JwtPayload>(this.access);
            if(decoded.exp && (decoded.exp * 1000) > new Date().getTime()) {
              return true;
            } 
            return false;
        }
        return false;
      } catch(e) {
        console.error(e);
        return false;
      }
    }
  },
});
