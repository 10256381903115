import { defineStore } from 'pinia';
import axios from 'axios';
import { useTokenStore } from '../TokenOperationStoreGroup/TokenOperation';
import { CustomerStocks, CustomerStocksStore } from './type';
import { IUniversalDataReturns } from '@/store/type';

export const useCustomerStocksStore = defineStore('useCustomerStocksStore', {
  state: (): CustomerStocksStore => ({
    customer_stocks: [],
    loading: true,
  }),
  actions: {
    async getCustomersStoks() {
        try {
            this.loading = true;
            const tokenStore = useTokenStore();
            const { data } = await axios.get<IUniversalDataReturns<CustomerStocks>>(process.env.VUE_APP_SERVER + 'api/customer_stocks/', {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
            this.customer_stocks = data.results;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    },
    async setCustomersStoks(customers_id: number, location_id: number) {
        try {
            this.loading = true;
            const tokenStore = useTokenStore();
            const { data } = await axios.post<CustomerStocks>(process.env.VUE_APP_SERVER + 'api/customer_stocks/',
            {
                customer: customers_id,
                location: location_id,
            },
            {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
            this.customer_stocks.push(data);
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    },
    async deleteCustomersStocks(id: number) {
    try {
        const tokenStore = useTokenStore();
        await axios.put(process.env.VUE_APP_SERVER + `api/customer_stocks/${id}/`,
        {
            customer: null,
        },
        {
            headers: { Authorization: `Bearer ${tokenStore.access}` },
            withCredentials: true,
        });
        if(this.customer_stocks) {
            this.customer_stocks = this.customer_stocks.filter((customer_stock) => customer_stock.id !== id);
        }
      } catch (e) {
            console.error(e);
      }
    }
  },
});
