<template>
    <div class="wrapper-input-options-group" @click.stop="focused = true" :class="{ skeleton: loading }"> 
        <div class="wrapper-input-group"> 
            <div class="sub-wrapper-input-group">
            <input
                class="input-group"
                v-model="value"
                :name="name"
                :placeholder="placeholder"
                :maxlength="max"
                :class="{ input_error: errorMessage }"
                :disabled="disabled"
                @keyup="keyHandler"
            />
            <span
                class="input-group__delete-icon"
                :class="{'input-group__delete-icon_error': errorMessage}"
                @click="deleteHandler"
                v-show="value"
            />
            </div>
            <span class="input-group__error">{{ errorMessage }}</span>
        </div> 
        <div
            v-show="focused"
            class="drop-down"
        >
            <span class="drop-down__value" v-if="!options.length && !loading">
                {{ $t('leer') }}
            </span>
            <span class="drop-down__value" v-else-if="loading">
                {{ $t('wird geladen') }}
            </span>
            <span class="drop-down__value" v-for="data in options" :key="data.id" @click.self.stop="valueHandler(data.name)">
                {{ data.name }}
            </span>
        </div>
    </div> 
</template>
<script setup lang="ts">
import { ref, defineProps, withDefaults, watch, onUnmounted, onMounted } from "vue";
import { PropsType } from './type';
import { useField } from "vee-validate";
import { string } from 'yup';

const focused = ref(false)
const props = withDefaults(defineProps<PropsType>(), {
    name: '',
    placeholder: '',
    max: 55,
    options: () => [],
    loading: false,
    disabled: true,
});

const deleteValue = ref(false);

const deleteHandler = () => {
    handleChange('', true);
    deleteValue.value = true;
}

const foundFromOptions = (value: string | undefined) => {
    if(!value){
        if(props.disabled) {
            return true;
        }
        return false;
    }
    const find = props.options.find((options) => options.name.toLowerCase() === value.toLowerCase());
    if(find && find.name) {
        return true;
    } else {
        return false;
    }
}

const { value, handleChange, errorMessage } = useField<string>(() => props.name, string().min(1, 'Ungültige eingabe').test("found", 'Not found', foundFromOptions));

const keyHandler = (e: KeyboardEvent) => {
    if (e.key === "Backspace") {
        deleteValue.value = true;
    } else {
        deleteValue.value = false;
    }
}

watch(() => value.value, (newState) => {
    const filterOptions = props.options.filter((options) => options.name.startsWith(newState));
    if(filterOptions.length === 1 && !deleteValue.value) {
        handleChange(filterOptions[0].name);
        return;
    }
});

watch(() => props.disabled, (newState) => {
    if(newState) {
        handleChange('');
    }
});

watch(()=> props.options, (newState) => {
    if(newState.length === 1) {
        handleChange(newState[0].name);
        return;
    }
});

const closeHandler = () => focused.value = false;

const valueHandler = (values: string) => {
    handleChange(values);
    closeHandler();
}

onMounted(() => {
    document.addEventListener('click', closeHandler);
})

onUnmounted(() => {
    document.removeEventListener('click', closeHandler);
})
</script>
  
<style lang="scss" src="./inputOptionsComponent.scss" />
  