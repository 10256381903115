<template>
    <div class="wrapper-input-group"> 
      <div class="sub-wrapper-input-group">
        <textarea
          class="input-group"
          v-model="value"
          :name="name"
          :rows="rows"
          :placeholder="placeholder"
          :maxlength="max"
          :class="{ input_error: errorMessage }"
          :disabled="disabled"
        />
        <span
          v-if="!disabled"
          class="input-group__delete-icon"
          :class="{'input-group__delete-icon_error': errorMessage}"
          @click="handleChange('', true)"
          v-show="value"
        />
      </div>
      <span class="input-group__error">{{ errorMessage }}</span>
    </div> 
  </template>
  <script setup lang="ts">
  import { defineProps } from "vue";
  import { useField } from "vee-validate";
  
  const props = defineProps({
    name: {
      type: String,
      required: true,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    max: {
      type: Number,
      default: 55,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: String,
      default: "",
    }
  });
  
  const { value, errorMessage, handleChange  } = useField(() => props.name);
  </script>
  
  <style lang="scss" src="./textareaComponent.scss" />
  