import { defineStore } from "pinia";
import { InitialDeliveryAddressState, InitialDeliveryAddressStateYup, LieferadresseStoreStateGroup } from "./type";

export const defaultValueUseLieferadresseStore = {
   plz_lifeAddress: '',
   einLand_lifeAddress: '',
   dieStadt_lifeAddress: '',
   adresse_lifeAddress: '',
}

export const useLieferadresseStore = defineStore("useLieferadresseStore", {
   state: (): LieferadresseStoreStateGroup => ({
      selected: null,
      initialValue: {
         ...defaultValueUseLieferadresseStore,
      },
      data: [],
   }),
   getters: {
      getInitialState(state) {
         return {
            ...state.initialValue,
         };
      },
   },
   actions: {
      setInitialValue(value: InitialDeliveryAddressStateYup) {
         this.initialValue = {
            ...value
         };
      },
      deleteAddresse(id: number) {
         if(this.data) {
            this.data = this.data.filter((addresse) => addresse.id !== id);
         }
      },
      addNewvalue(value: InitialDeliveryAddressState) {
         if(this.data) {
            this.data.push(value);
         } else {
            this.data = [value];
         }
      }
   }
});
