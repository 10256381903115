import { useKontodetailsStore } from "@/store/modules/AccountAngabenStoreGroup/KontodetailsStore/KontodetailsStore";
import { useLieferadresseStore } from "@/store/modules/AccountAngabenStoreGroup/LieferadresseStore/LieferadresseStore";
import { useRechnungsadresseStore } from "@/store/modules/AccountAngabenStoreGroup/RechnungsadresseStore/RechnungsadresseStore";
import { userDataStore } from "@/store/modules/AccountAngabenStoreGroup/UserGroup/userData";
import { customerDataStore } from "@/store/modules/CustomersGroup/customerData";
import { useCustomerStocksStore } from "@/store/modules/CustomerStocksStore/CustomerStocksStore";
import { useAllLocationsStore } from "@/store/modules/LocationsStoreGroup/AllLocationsStore/AllLocationsStore";
import { useLocationsStore } from "@/store/modules/LocationsStoreGroup/LocationsStore/LocationsStore";
import { useTokenStore } from "@/store/modules/TokenOperationStoreGroup/TokenOperation";
import { useUserTranslate } from "@/store/modules/TranslateStoreGroup/TranslateStore";
import { Language } from "@/store/modules/TranslateStoreGroup/type";
import { useUserLoginStore } from "@/store/modules/UserLoginStore/UserLoginStore";


export const getAccountData = async () => {
    const tokenStore = useTokenStore();
    const userData = userDataStore();
    const customerData = customerDataStore();
    const KontodetailsStore = useKontodetailsStore();
    const LoginStore = useUserLoginStore();
    const customerLocationsStore = useLocationsStore('customer');
    const AllLocationsStore = useAllLocationsStore();
    const LieferadresseStore = useLieferadresseStore();
    const CustomerStocksStore = useCustomerStocksStore();
    const RechnungsadresseStore = useRechnungsadresseStore();
    const translateStore = useUserTranslate();

    const findCustomerData = () => {
        const find = AllLocationsStore.locations.find((location) => location.id === customerData.customers.location);
        if(find) {
          const dieStadt = find.geo_place_name ? `${find.geo_place_region} ${find.geo_place_name}` : `${find.geo_place_region}`;
          customerLocationsStore.locations = find;
          RechnungsadresseStore.setInitialValue({
            plz: find.postal_code || '',
            einLand: find.country || '',
            dieStadt,
            adresse: find.full_address || '',
          });
        }
      }

    tokenStore.loading = false;
    if(tokenStore.decode) {
      await userData.getUserData(tokenStore.decode?.user_id);

      translateStore.languageSelect = userData.user.ui_language.substring(0, 2) as Language;
      
      await CustomerStocksStore.getCustomersStoks();
      await AllLocationsStore.getLocations();
      LoginStore.setInitialState({
        Kundennummer: userData.user.external_user_id,
        Email: userData.user.email || '',
      });
      if(userData.user.customer) {
        await customerData.getCustomersData(userData.user.customer)
          .then(() => {
            AllLocationsStore.locations.forEach((location) => {
              if(customerData.customers.location !== location.id) {
                LieferadresseStore.selected = location.id;
                LieferadresseStore.setInitialValue({
                  plz_lifeAddress: location.postal_code || '',
                  einLand_lifeAddress: location.country  || '',
                  dieStadt_lifeAddress: location.city || '',
                  adresse_lifeAddress: location.full_address || '',
                });
                LieferadresseStore.addNewvalue({
                  id: location.id || 0,
                  plz_lifeAddress: location.postal_code || '',
                  einLand_lifeAddress: location.country  || '',
                  dieStadt_lifeAddress: location.city || '',
                  adresse_lifeAddress: location.full_address || '',
                });
              }
            });
            findCustomerData();
            KontodetailsStore.setInitialState({
              firma: customerData.customers.firm_name || '',
              kontaktperson: customerData.customers.manager || '',
              telefonenumber: customerData.customers.phone || '',
              zusatzlicheTelefonnummer: customerData.customers.fax || '',
              mwst_num: customerData.customers.buyer_vat || '',
              gln_num: '',
            });
          });
      }
    }
}