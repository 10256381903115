import { defineStore } from "pinia";
import axios from "axios";
import { useTokenStore } from "@/store/modules/TokenOperationStoreGroup/TokenOperation";
import { FavoriteStoreState, FavoriteData, FavoriteItem } from "./type";
import { CardStoreType } from "../WebshopStoreGroup/CardStoreGroup/type";

export const useFavoriteStore = defineStore("favoriteStore", {
  state: (): FavoriteStoreState => ({
    favorites: [],
    loading: true,
    horizontal: true,
    count: 0,
    next: null,
    previous: null,
    results: [],
  }),
  getters: {
    getFavorite: (state) => (productId: number) => state.favorites.find(favorite => favorite.product.id === productId),
    isFavorite: (state) => (productId: number) => state.results.some(favorite => favorite.product === productId),
  },
  actions: {
    async getFavoriteItem(id: number) {
      const tokenStore = useTokenStore();
      try {
        const { data } = await axios.get<CardStoreType>(
          process.env.VUE_APP_SERVER +`api/search/${id}/`,
          {
            headers: { Authorization: "Bearer " + tokenStore.access },
            withCredentials: true,
          }
        );
        this.favorites.push(data);
      } catch (e) {
        console.error(e);
      }
    },
    async fetchFavoriteCards(page = 1) {
      const tokenStore = useTokenStore();
      this.loading = true;
      try {
        const { data } = await axios.get<FavoriteStoreState>(
          process.env.VUE_APP_SERVER + "api/favorites/",
          {
            params: { page },
            headers: { Authorization: "Bearer " + tokenStore.access },
            withCredentials: true,
          }
        );
        this.results = data.results;
        this.count = data.count;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async addFavorite(itemId: number) {
      if (!this.results.some(favorite => favorite.product === itemId)) {
        const tokenStore = useTokenStore();
        try {
          const { data } = await axios.post<FavoriteData>(
            process.env.VUE_APP_SERVER + "api/favorites/",
            { product: itemId },
            {
              headers: { Authorization: "Bearer " + tokenStore.access },
              withCredentials: true,
            }
          );
          this.results.push(data);
        } catch (e) {
          console.error(e);
        }
      }
    },
    async removeFavorite(itemId: number) {
      const tokenStore = useTokenStore();
      const favorite = this.results.find((fav: FavoriteItem) => fav.product === itemId);
      if (!favorite) return;
      try {
        await axios.delete<FavoriteData>(
          process.env.VUE_APP_SERVER + `api/favorites/${favorite.id}/`,
          {
            headers: { Authorization: "Bearer " + tokenStore.access },
            withCredentials: true,
          }
        );
        this.results = this.results.filter((fav: FavoriteItem) => fav.product !== itemId);
        this.favorites = this.favorites.filter((fav) => fav.product.id !== itemId);
      } catch (e) {
        console.error(e);
      }
    }
  },
});
