<template>
  <div class="wrapper-card" v-for="statusData in statusStore.results" :key="statusData.id">
      <div class="card-title">
        <span class="">
          <span
            :style="{color: colorStatus(statusData.status)}"
          >
            {{ textStatus(statusData.status) }}
          </span>
           {{ statusData.number }} vom {{ statusData.date }}
        </span>
        <ButtonBackgroundComponent :class="'card-button'">
          {{ $t('bestellung wiederholen') }}
        </ButtonBackgroundComponent>
      </div>
      <CardInfoComponent :order_id="statusData.id" />
  </div>
</template>

<script setup lang="ts">
import ButtonBackgroundComponent from "@components/ui/buttonGroup/buttonBackgroundGroup/buttonBackgroundComponent.vue";
import CardInfoComponent from "@components/StatusCardGroup/CardInfoComponent/CardInfoComponent.vue";
import { StatusGroupStore } from "@/store/modules/StatusGroup/StatusGroupStore";

const statusStore = StatusGroupStore();
// need change to enum type
const textStatus = (status: string) => {
    switch(status) {
        case 'WAITING':
            return 'Negotiating';
        case 'OPENED':
          return 'Agreed';
        case 'ISSUED':
          return 'In progress';
        case 'CANCELED':
          return 'Canceled by customer';
        case 'CLOSED':
          return 'Fulfilled';
        default:
          return '';
    }
}

const colorStatus = (status: string) => {
    switch(status) {
        case 'WAITING':
            return '#B88500';
        case 'OPENED':
          return '#D9EBDC';
        case 'ISSUED':
          return '#111111';
        case 'CANCELED':
          return '#FBE6E8';
        case 'CLOSED':
          return '#D9EBDC';
        default:
          return '';
    }
}
</script>

<style src="./StatusCardComponent.scss" lang="scss" />
