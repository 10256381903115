import { defineStore } from 'pinia';
import type { UserLoginStoreGroup, UserLoginStoreGroupState } from './type';

export const useUserLoginStore = defineStore('useUserLoginStore', {
  state: (): UserLoginStoreGroup => ({
    initialValue: {
      Kundennummer: null,
      Email: '',
    }
   
  }),
  getters: {
    getInitialState (state) {
      return {
        ...state.initialValue,
      }
    }
  },
  actions: {
    setInitialState (state: UserLoginStoreGroupState) {
      this.initialValue = {...state};
    }
  },
});
