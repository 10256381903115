import { defineStore } from 'pinia';
import type { CreateLocationsType, LocationsStoreGroupState, LocationsType } from '../type';
import { useTokenStore } from '../../TokenOperationStoreGroup/TokenOperation';
import axios from 'axios';
import { IUniversalDataReturns } from '@/store/type';

export const useAllLocationsStore = defineStore('useLocationsStore', {
  state: (): LocationsStoreGroupState<LocationsType[]> => ({
    locations: [],
    loading: true,
  }),
  getters: {
    getLocationById() {
        return (id: number) => {
            return this.locations.find((location) => location.id === id);
        }
    }
  },
  actions: {
    async getLocations() {
        try {
            this.loading = true;
            const tokenStore = useTokenStore();
            const { data } = await axios.get<IUniversalDataReturns<LocationsType>>(process.env.VUE_APP_SERVER + 'api/locations/', {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
            this.locations = data.results;
            return data;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    },
    async setLocations(state: CreateLocationsType) {
        try {
            this.loading = true;
            const tokenStore = useTokenStore();
            const { data } = await axios.post<LocationsType>(process.env.VUE_APP_SERVER + 'api/locations/',
            { ...state },
            {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
            return data;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    },
    async updateLocations(state: CreateLocationsType, id: number) {
        try {
            this.loading = true;
            const tokenStore = useTokenStore();
            const { data } = await axios.put<LocationsType>(process.env.VUE_APP_SERVER + `api/locations/${id}/`,
            { ...state },
            {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
            this.locations = [...this.locations.filter((location) => location.id !== id), data];
            return data;
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    },
  },
});
