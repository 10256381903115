<template>
    <div class="wrapper-login">
        <div class="close-button" @click="popupStore.changeOpenPopupStore(false)"/>
        <span class="login-title">{{ $t('melden sie sich in ihrem konto an') }}</span>
        <form class="login-form" @submit.prevent="handleSubmitLogin">
            <div class="wrapper-input-login">
                <span class="sub-login-title">{{ $t('email oder Kundennummer') }}</span>
                <Input :max="55" :name="'email'" :placeholder="$t('email_login')" />
            </div>
            <div class="wrapper-input-login">
                <span class="sub-login-title">{{ $t('password') }}</span>
                <inputPassword :max="55" :name="'password'" :placeholder="$t('password')" />
            </div>
            <div class="wrapper-login-reset">
                <div class="sub-wrapper-login-reset">
                    <span class="login-text">{{ $t('nicht registriert?') }}</span>
                    <ButtonWithoutBackgroundComponent>
                        {{ $t('ein Konto erstellen') }}
                    </ButtonWithoutBackgroundComponent>
                </div>
                <ButtonWithoutBackgroundComponent>
                    {{ $t('passwort vergessen?') }}
                </ButtonWithoutBackgroundComponent>
            </div>
            <ButtonBackgroundComponent :class="'login-button'" :type="'submit'">
                {{ $t('melden sie sich in ihrem konto an') }}
            </ButtonBackgroundComponent>
            <span class="input-group__error">{{ errors.detail }}</span>
        </form>
    </div>
</template>

<script setup lang="ts">
import ButtonBackgroundComponent from "@/components/ui/buttonGroup/buttonBackgroundGroup/buttonBackgroundComponent.vue";
import ButtonWithoutBackgroundComponent from "@/components/ui/buttonGroup/buttonWithoutBackgroundGroup/buttonWithoutBackgroundComponent.vue";
import { useTokenStore } from "@/store/modules/TokenOperationStoreGroup/TokenOperation";
import { usePopupStore } from "@/store/modules/PopupStoreGroup/PopupStore";
import Input from "@components/ui/inputGroup/inputComponent/inputComponent.vue";
import inputPassword from "@components/ui/inputGroup/inputPasswordComponent/inputPasswordComponent.vue";
import { loginGroupSchema } from '@/yup/loginGroupSchema/loginSchema';
import { ILoginGroupSchema } from '@/yup/loginGroupSchema/type';
import { useForm } from "vee-validate";
import { useRouter } from "vue-router";
import { getAccountData } from "@/utils/getUserDataUtils/getUserDataUtils";
import { useFavoriteStore } from "@/store/modules/FavoriteStoreGroup/FavoriteStore";

const popupStore = usePopupStore('login');
const tokenStore = useTokenStore();
const router = useRouter();
const favoriteStore = useFavoriteStore();
const { handleSubmit, setErrors, errors } = useForm<ILoginGroupSchema>({
    initialValues: {
        email: '',
        password: '',
        detail: '',
    },
    validationSchema: loginGroupSchema,
});

const handleSubmitLogin = handleSubmit((values) => tokenStore.userLogin(values)
.then(() => {
    popupStore.open = false;
    router.push({ name: 'mein_konto' });
}).then(() => {
    getAccountData();
    favoriteStore.fetchFavoriteCards();
}).catch((error) => {
    const errors = JSON.parse(error.request.response);
    Object.keys(errors).forEach((value) => {
        setErrors({
            [value]: errors[value],
        });
    })
}));
</script>

<style lang="scss" src="./PopupLoginComponent.scss" />
