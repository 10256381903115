<template>
    <div class="wrapper-detail" v-for="details in detailsData" :key="details.id">
        <div class="card-info">
            <div class="wrapper-card-info__text">
                <span class="card-info__text">{{ $t('bezahlstatus') }}</span>
                <span
                    class="card-info__text--payment-status"
                    :style="{
                        color: colorStatus(details.status)
                    }"
                >{{ details.status }}</span>
            </div>
            <div class="wrapper-card-info__text">
                <span class="card-info__text">{{ $t('gesamtbetrag') }}</span>
                <span class="card-info__text">CHF {{ details.local_total_inc_vat }}</span>
            </div>
        </div>
        <div class="card-goods-info">
            <img
                class="card-goods-info__img"
                :src="details.product_photo_path"
                :alt="details.full_name"
              />
            <div class="sub-card-goods-info">
                <div class="card-product-info">
                    <span class="card-info__text">{{ details.full_name }}</span>
                    <span class="card-info__text">{{ Number(details.amount) }}</span>
                    <span class="card-info__text">CHF {{ details.local_total_inc_vat }}</span>
                    <span
                        class="card-info__text"
                        :style="{
                            color: colorStatus(details.status)
                        }"
                    >
                        {{ details.stock_delivery_time }}
                    </span>
                </div>
                <div class="sub-sub-card-goods-info">
                    <div class="wrapper-card-info__text">
                        <span class="card-info__text">{{ $t('size') }}</span>
                        <span class="card-info__text">{{   }}</span>
                    </div>
                    <div class="wrapper-card-info__text">
                        <span class="card-info__text">{{ $t('colour') }}</span>
                        <span class="card-info__text">{{  }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup lang="ts">
import { OrderDetailsStore } from "@/store/modules/StatusGroup/StatusGroupStore";
import { computed, defineProps, onMounted, onUnmounted } from "vue";

const props = defineProps({
    order_id: {
        type: Number,
    }
});

const statusStore = OrderDetailsStore();
const detailsData = computed(() => statusStore.results);
// need change to enum type
const colorStatus = (status: string) => {
    switch(status) {
        case 'NORMAL':
            return 'green'
    }
}

onMounted(() => {
    if(props.order_id) {
        statusStore.getOrderDetails(props.order_id);
    }
});

onUnmounted(() => {
    statusStore.results = [];
});
</script>

<style src="./CardInfoComponent.scss" lang="scss" />
  