<template>
    <HeaderComponent />
    <main>
      <router-view />
    </main>
    <FooterComponent />
</template>

<script setup lang="ts">
import HeaderComponent from "@/components/HeaderComponentGroup/HeaderComponent/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent/FooterComponent.vue";
import { onMounted } from "vue";
import { useTokenStore } from "./store/modules/TokenOperationStoreGroup/TokenOperation";
import { getAccountData } from "./utils/getUserDataUtils/getUserDataUtils";
import { useFavoriteStore } from "./store/modules/FavoriteStoreGroup/FavoriteStore";
import { useShoppingCartStore } from "./store/modules/ShoppingCartStoreGroup/ShoppingCartStore";

const favoriteStore = useFavoriteStore();
const tokenStore = useTokenStore();
const shoppingCartStore = useShoppingCartStore();

onMounted(() => {
  tokenStore.refreshAccessToken()
    .then(async(e: boolean) => {
      if(!e) {
        shoppingCartStore.loading = false;
        favoriteStore.loading = false;
        return;
      }
        await getAccountData();
        favoriteStore.fetchFavoriteCards();
        shoppingCartStore.getCart();
    });
});
</script>

<style lang="scss" src="@style/global.scss"/>
