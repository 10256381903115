import { router } from './routes/routes';
import { createApp } from 'vue';
import App from './App.vue';
import { pinia } from './store/store';
import { i18n } from './i18n';

import InputOptionsComponent from "@components/ui/inputGroup/inputOptionsComponent/inputOptionsComponent.vue";
import InputComponentMinWidth from "@components/ui/inputGroup/InputComponentMinWidth/InputComponentMinWidth.vue";
import InputComponent from "@components/ui/inputGroup/inputComponent/inputComponent.vue";
import ButtonWithoutBackground from "@components/ui/buttonGroup/buttonWithoutBackgroundGroup/buttonWithoutBackgroundComponent.vue";
import InputPhone from "@components/ui/inputGroup/inputComponentPhone/inputComponentPhone.vue";
import StatusCardComponent from "@components/StatusCardGroup/StatusCardComponent/StatusCardComponent.vue";
import ButtonBackgroundComponent from "@components/ui/buttonGroup/buttonBackgroundGroup/buttonBackgroundComponent.vue";
import TextareaComponent from '@components/ui/textareaGroup/textareaComponent/textareaComponent.vue';
import DragAndDropFileUploaderComponent from '@components/DragAndDropFileUploaderComponent/DragAndDropFileUploaderComponent.vue';
import BreadCrumbsComponent from '@components/ui/breadCrumbs/breadCrumbsComponent.vue';
import CheckboxComponent from '@components/ui/inputGroup/checkboxComponent/checkboxComponent.vue';

const app = createApp(App);

app.component('DragAndDropFileUploaderComponent', DragAndDropFileUploaderComponent);
app.component('TextareaComponent', TextareaComponent);
app.component('InputOptionsComponent', InputOptionsComponent);
app.component('InputComponentMinWidth', InputComponentMinWidth);
app.component('InputComponent', InputComponent);
app.component('ButtonWithoutBackground', ButtonWithoutBackground);
app.component('InputPhone', InputPhone);
app.component('StatusCardComponent', StatusCardComponent);
app.component('ButtonBackgroundComponent', ButtonBackgroundComponent);
app.component('BreadCrumbsComponent', BreadCrumbsComponent);
app.component('CheckboxComponent', CheckboxComponent);

app.use(i18n);
app.use(router);
app.use(pinia);

app.mount('#app');
