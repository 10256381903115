export const enum Language {
    DE = 'DE',
    FR = 'FR',
    IT = 'IT',
}

export type TranslateStoreState = {
    languageSelect: Language,
    language: {
        [key:string]: Language
    }
}
