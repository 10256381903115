import { defineStore } from 'pinia';
import type { RechnungsadresseGroup, RechnungsadresseState } from './type';

export const useRechnungsadresseStore = defineStore('useRechnungsadresseStore', {
  state: (): RechnungsadresseGroup => ({
    initialValue: {
      plz: '',
      einLand: '',
      dieStadt: '',
      adresse: '',
    }
  }),
  getters: {
    getInitialState (state) {
      return {
        ...state.initialValue,
      }
    },
  },
  actions: {
    setInitialValue(state: RechnungsadresseState) {
      this.initialValue = {
         ...state
      };
   },
  }
});
