<template>
    <div v-if="popupStore.open" class="wrapper-popup" @click.self="popupStore.changeOpenPopupStore(false)">
        <div class="popup">
            <slot/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { usePopupStore } from '@/store/modules/PopupStoreGroup/PopupStore.ts';
import { defineProps } from 'vue';

const props = defineProps({
    name: {
        type: String,
        default: 'popup'
    }
});

const popupStore = usePopupStore(props.name);
</script>

<style lang="scss" src="./PopupComponent.scss" />
